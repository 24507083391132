@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.active {
  border-left: 5px solid #1e7e71 !important;
  background-color: #1e7e711d !important;
  color: #1e7e71 !important;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}

/* for maps */
.gm-fullscreen-control {
  display: none !important; /* Hide the fullscreen control */
}

div .gmnoprint {
  display: none !important; /* Hide the satellite view control */
}
